<template>
    <!-- 答疑解惑 -->
    <div class="explain wrapper">
        <proTitle></proTitle>
        <el-form :inline="true" class="demo-form-inline flex-align-between" @submit.native.prevent>
            <el-form-item label="状态:">
                <el-select v-model="formInline.replyState" placeholder="全部" @change="filterList()" size="mini">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="待回复" value="0"></el-option>
                    <el-option label="已回复" value="1"></el-option>
                </el-select>
            </el-form-item>
            <div class="download-inline">
                <button class="export-btn csp" @click="exportFile">
                    <i class="export-icon"></i>导出</button>
            </div>
        </el-form>
        <div class="table_section">
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column prop="number" label="序号" width="80" align="center" type="index" :index="indexMethod" fixed>
                </el-table-column>
                <el-table-column prop="title" label="标题" align="center" width="170" fixed show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="createTime" label="发布时间" align="center" width="150">
                    <template slot-scope="scope">
                        {{ dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="教师姓名" align="center" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="replyCount" label="状态" align="center" width="100">
                    <template slot-scope="scope">
                        {{ scope.row.replyCount > 0 ? '已回复' : '未回复' }}
                    </template>
                </el-table-column>
                <el-table-column prop="replyCount" label="培训阶段" align="center">
                    <template slot-scope="scope">
                        {{ mark[scope.row.mark] }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button @click="openReply(scope.row)" type="text" size="small">回复</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination :total="pages.total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[10, 20, 30]" @pagination="handlePageChange">
            </pagination>
        </div>
        <explainReply :visible="visibleReplyDialog" @close="closeReply" :questionId="selectedQuestion.id"
            :questionTitle="selectedQuestion.title" @getExplainList="getExplainList">
        </explainReply>
    </div>
</template>

<script>
import explainReply from './explain-reply.vue'
export default {
    data() {
        return {
            tableData: [],
            pages: { //分页
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            formInline: {
                replyState: '',
            },
            visibleReplyDialog: false,
            selectedQuestion: {},
            mark: {
                0: '暂无',
                1: '集中培训',
                2: '跟岗研修',
                3: '返岗实践'
            }
        };
    },
    components: {
        explainReply
    },
    watch: {
        '$store.state.user.currentProject.id': {
            handler(newVal, oldVal) {
                if (!newVal) return;
                this.pages.currentPage = 1;
                this.getExplainList();
            }
        }
    },
    methods: {
        indexMethod(index) {
            return this.pages.currentPage > 1 ?
                (this.pages.pageSize * (this.pages.currentPage - 1)) + index + 1 :
                index + 1
        },
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            this.getExplainList();
        },
        openReply(item) {
            this.visibleReplyDialog = true;
            this.selectedQuestion = item;
        },
        closeReply() {
            this.visibleReplyDialog = false;
            this.selectedQuestion = {};
        },
        // 状态筛选
        filterList() {
            this.pages.currentPage = 1;
            this.getExplainList();
        },
        // 获取周报列表
        async getExplainList() {
            let pramas = {
                page: this.pages.currentPage,
                limit: this.pages.pageSize,
                projectId: this.$store.state.user.currentProject.id,
            }
            pramas.replyCount = this.formInline.replyState ? this.formInline.replyState : null
            let resData = await this.$Api.Course.getExplainList(pramas);
            // console.log(resData);
            this.tableData = resData.data.records
            this.pages.total = resData.data.total
        },
        async exportFile() {
            // let params = {
            //     // id: s this.$store.state.user.currentProject.id
            //     id: 195
            // }
            // let resData = await this.$Api.Project.exportWorkStatisticInfo(params)
            // console.log(resData);


            this.$message.info('正在导出...')
            let projectId = this.$store.state.user.currentProject.id
            window.location.href =
                `${this.commonbaseURL}/course/comment/projectQuestion/export?projectId=${projectId}`;
        },
    },
    mounted() {
        this.getExplainList();
    },
    activated() { }
}
</script>

<style lang="less" scoped>
@import "../manage/less/table.less";

.explain {
    padding: 20px 25px;
    box-sizing: border-box;

    .demo-form-inline {
        margin: 15px 0;

        @media screen and (min-width:1250px) {
            margin: 20px 0;
        }
    }

    .download-inline {
        .export-btn {
            color: #595959;
        }

        .export-icon {
            display: inline-block;
            background-image: url("~@/assets/images/admin/export.png");
            background-size: 100% 100%;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            vertical-align: -2px;
        }
    }

    .table_section {
        /deep/.el-table th {
            &>.cell {
                white-space: pre-line;
            }
        }
    }
}
</style>